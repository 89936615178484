$break-small: 400px;

.App {
  z-index: 9;
  height: 100%;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

.main-container {
  position: relative;
}

.grid-container {
  justify-items: center;
  align-items: center;
  padding-top: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-row-gap: 4rem;
  padding-bottom: 12rem;
  background-color: #a41a18;
}

.about {
  height: 100%;
  background: rgb(2,0,36);

  padding-top: 4rem;
  padding-bottom: 20rem;

  color: white;
  z-index: 10;
  position: relative;

  h1 {
    color: #f9f9f9;
    margin-top: 2rem;
  }

  .about-body {
    color: white;
  }

  p {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 1rem;
  }
}

.luke-side {
  height: 100%;
  background: rgb(2,0,36);

  padding-top: 4rem;
  padding-bottom: 8.2rem;

  color: white;
  z-index: 10;
  position: relative;

  p {
    margin: 0 auto;
    margin-bottom: 2rem;
    @media screen and (max-width: $break-small) {
      width: 300px;
    }
  }

  i {
    margin: 0 auto;
  }

  img {
    border: 5px solid black;
    z-index: 100;

    @media screen and (max-width: $break-small) {
      width: 300px;
    }
  }

}

a.link-luke:hover {
  text-decoration: none;
}

.header {
  display: flex;

  img {

  }
}

div[class^="nummer-"] {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}


.nummer-even {
  color: black;
  font-size: 8rem;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}

.navbar {
  z-index: 9999 !important;
}

a{
  position:relative;
  z-index:9999;
}


.nummer-even-disabled {
  color: black;
  font-size: 8rem;
}

.nummer-odd-disabled {
  color: #f9f9f9;
  font-size: 8rem;
}

.nummer-even:hover {
  color: #f9f9f9;
}

.nummer-odd {
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
  color: #f9f9f9;
  font-size: 8rem;
}

.nummer-odd:hover {
  color: black;
}

.post-element {
  height: 200px;
  width: 200px;
}

.even {
  background-color: #f9f9f9;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}

.even:hover {
  color: #f9f9f9;
  background-color: black;
}

.odd {
  background-color: black;
  -o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}

.odd:hover {
  background-color: #f9f9f9;
}

.title {
  padding: 1rem;
}

.oppgavetekst {
  max-width: 500px;
  margin: 0 auto;
}

#løsning {
  color: black;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 2rem;
}
.løsningwrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.notfound {
  color: white;
  margin-top: 4rem;
}

#solutionButton {
  background-color: #a41a18;
  border-color: #a41a18;
}

.oppgave-del {
  z-index: 100;
}

.partikler canvas {
  display: block;
  vertical-align: bottom;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.partikler {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
}

.countdown {
  color: white;
  font-weight: bold;
  font-size: 40px;
  margin-top: 4rem;
}

.luke-beskjed {
  margin-bottom: 1rem;
}

.card-body {
  text-align: left;
}